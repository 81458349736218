import ReactDOM from 'react-dom/client';

import { Application } from 'shared/types/application';

import reportWebVitals from './reportWebVitals';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const { REACT_APP_APPLICATION: application } = process.env;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

function importBuildTarget() {
  if (application === Application.GSPManager) {
    return import('applications/GSPManager/GSPManager');
  }

  if (application === Application.GSPLandingPage) {
    return import('applications/GSPLandingPage/GSPLandingPage');
  }

  return import('applications/GSP/GSP');
}

importBuildTarget().then(({ default: App }) => root.render(<App />));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
